const state = {
  text: "", // 提示文字
  show: false, // 是否显示
  color: "", // 颜色
  closeBtn: false, // 是否显示关闭按钮
  closeBtnType: "icon", // icon or text 关闭按钮的类型
  timeout: 5000, // 自动关闭的时间
  callback: null, // 关闭后的回调
};

let messageTimer = null;

const mutations = {
  SET_MESSAGE: (state, message) => {
    if (messageTimer) {
      clearTimeout(messageTimer);
    }
    state = Object.assign(state, message);
    if (state.show && state.timeout > 0) {
      // 自动关闭
      messageTimer = setTimeout(() => {
        state = Object.assign(state, {
          show: false,
        });
        if (state.callback && typeof state.callback === "function") {
          state.callback(state);
        }
      }, state.timeout);
    }
  },
};

const actions = {
  success({ commit }, text) {
    return new Promise(() => {
      commit("SET_MESSAGE", {
        text: text,
        color: "success",
        show: true,
      });
    });
  },
  error({ commit }, text) {
    return new Promise(() => {
      commit("SET_MESSAGE", {
        text: text,
        color: "error",
        show: true,
      });
    });
  },
  warning({ commit }, text) {
    return new Promise(() => {
      commit("SET_MESSAGE", {
        text: text,
        color: "warning",
        show: true,
      });
    });
  },
  info({ commit }, text) {
    return new Promise(() => {
      commit("SET_MESSAGE", {
        text: text,
        color: "info",
        show: true,
      });
    });
  },
  hide({ commit }) {
    return new Promise(() => {
      commit("SET_MESSAGE", {
        show: false,
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
