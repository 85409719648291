import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("../views/login"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("../views/404"),
    hidden: true,
  },
  {
    path: "/",
    component: Layout,
    hidden: true,
    redirect: "/business/list",
    children: [
      // {
      //   path: "dashboard",
      //   component: () => import("../views/home/dashboard"),
      // },
      // {
      //   path: "/query",
      //   component: () => import("../views/home/query"),
      // },
      // {
      //   path: "auditLog",
      //   component: () => import("../views/home/audit-log"),
      // },
    ],
  },
  {
    path: "/enterprise",
    component: Layout,
    meta: { title: "接入企业管理" },
    redirect: "/enterprise/list",
    children: [
      {
        path: "list",
        name: "EnterpriseList",
        component: () => import("../views/enterprise/list"),
      },
    ],
  },
  {
    path: "/business",
    component: Layout,
    meta: { title: "备案业务管理" },
    redirect: "/business/list",
    children: [
      {
        path: "list",
        name: "BusinessList",
        component: () => import("../views/business/list"),
      },
    ],
  },
  // {
  //   path: "/contract",
  //   component: Layout,
  //   meta: { title: "授权凭证管理" },
  //   redirect: "/contract/list",
  //   children: [
  //     {
  //       path: "list",
  //       name: "ContractList",
  //       component: () => import("../views/contract/list"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
