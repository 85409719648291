<template>
  <v-container fluid>
    <v-app-bar app dense flat outlined clipped-right>
      <!-- <v-btn text to="/dashboard">首页</v-btn> -->
      <!-- <v-btn text to="/query">查询接口</v-btn> -->
      <!-- <v-btn text to="/auditLog">审计日志</v-btn> -->
      <v-spacer />
    </v-app-bar>
    <v-navigation-drawer app>
      <v-card>
        <v-card-title>存证服务平台</v-card-title>
        <v-card-subtitle>
          <a
            target="_blank"
            href="http://linkedsay.com"
            style="text-decoration: none"
          >
            linkedsay.com
          </a>
        </v-card-subtitle>
      </v-card>
      <v-list nav dense>
        <template v-for="route in $router.options.routes">
          <template v-if="!route.hidden">
            <v-list-item :key="route.path" :to="route.path">
              <v-list-item-content>
                <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
      <template v-slot:append>
        <!-- <v-container>
          <v-btn block @click="syncJobExec" :disabled="count > 3"
            >上传授权文件</v-btn
          >
        </v-container> -->
        <v-container>
          <v-btn block @click="logout">退出</v-btn>
        </v-container>
      </template>
    </v-navigation-drawer>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { SyncJobExec } from "@/api/dproof-storage";

export default {
  name: "ViewsLayout",
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    async syncJobExec() {
      this.count++;
      await SyncJobExec();
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
  },
};
</script>
