import request from "../utils/request";

export function ItemsQuery(item) {
  return request.get("/apis.dproof.storage/items/" + item);
}

export function AuthEnterpriseQuery(data) {
  return request.post("/apis.dproof.storage/auth/enterprise/query", data);
}

export function AuthEnterpriseAdd(data) {
  return request.post("/apis.dproof.storage/auth/enterprise/add", data);
}

export function AuthEnterpriseUpdate(data) {
  return request.post("/apis.dproof.storage/auth/enterprise/update", data);
}

export function AuthEnterprisePause(data) {
  return request.post("/apis.dproof.storage/auth/enterprise/pause", data);
}

export function AuthEnterpriseDelete(data) {
  return request.post("/apis.dproof.storage/auth/enterprise/delete", data);
}

export function AuthBusinessQuery(data) {
  return request.post("/apis.dproof.storage/auth/business/query", data);
}

export function AuthBusinessAdd(data) {
  return request.post("/apis.dproof.storage/auth/business/add", data);
}

export function AuthBusinessUpdate(data) {
  return request.post("/apis.dproof.storage/auth/business/update", data);
}

export function AuthBusinessDelete(data) {
  return request.post("/apis.dproof.storage/auth/business/delete", data);
}

export function AuthContractAdd(data) {
  return request.post("/apis.dproof.storage/auth/contract/add", data);
}

export function AuthContractUpdate(data) {
  return request.post("/apis.dproof.storage/auth/contract/update", data);
}

export function AuthContractList() {
  return request.post("/apis.dproof.storage/auth/contract/list");
}

export function AuthContractQuery(data) {
  return request.post("/apis.dproof.storage/auth/contract/query", data);
}

export function AuthContractDownload(data) {
  return request.post("/apis.dproof.storage/auth/contract/download", data);
}

export function AuthContractAsynup(data) {
  return request.post("/apis.dproof.storage/auth/contract/asynup", data);
}

export function CommRecordQuery(data) {
  return request.post("/apis.dproof.storage/auth/comm/query", data);
}

export function CommRecordDownload(data) {
  return request.post("/apis.dproof.storage/auth/comm/download", data);
}

export function AuthCommAsynup(data) {
  return request.post("/apis.dproof.storage/auth/comm/asynup", data);
}

export function AuditLogQuery(data) {
  return request.post("/apis.dproof.storage/audit-log/query", data);
}

export function SyncJobExec() {
  return request.post("/apis.dproof.storage/sync/job/exec");
}
