<template>
  <v-app style="background-color: #eceff1">
    <router-view />
    <message></message>
  </v-app>
</template>

<script>
import message from "./components/message";
export default {
  components: { message },
};
</script>
