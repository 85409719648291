import axios from "axios";
import store from "../store";
import { TokenKey, getToken } from "./cookie";

const service = axios.create({});

service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers[TokenKey] = "Bearer " + getToken();
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const data = response.data;

    if (data.code !== "200" && data.code !== 200) {
      store.dispatch("message/error", data.errorMsg);
      return Promise.reject(new Error(data.errorMsg || "Error"));
    } else {
      return data;
    }
  },
  (error) => {
    console.log("err" + error);

    return Promise.reject(error);
  }
);

export default service;
